import React from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import chaining from 'src/utils/chaining';

export default class SearchInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onPressEnter: PropTypes.func,
    live: PropTypes.bool,
    debounce: PropTypes.number,
    onSearch: PropTypes.func,
  };

  static defaultProps = {
    live: true,
    debounce: 0,
    onSearch: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      useDefault: true,
    };
    this.search = this.props.onSearch;

    if (this.props.debounce) {
      this.search = debounce(this.search, this.props.debounce);
    }
  }

  clearInput = (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      value: '',
      useDefault: false,
    });
    this.props.onSearch('');
  };

  handleChange = (v) => {
    v.stopPropagation();

    this.setState({
      value: v.target.value,
      useDefault: false,
    });
    if (this.props.live) {
      this.search(v.target.value);
    }
  };

  handlePressEnter = () => {
    if (this.props.live) {
      return;
    }

    this.search(this.state.value);
  };

  render() {
    const {
      className,
      autoFocus,
      placeholder,
      defaultValue,
      style,
      onPressEnter,
      onChange,
    } = this.props;

    const inputProps = omit(this.props, [
      'onSearch',
      'defaultValue',
      'live',
      'debounce',
    ]);
    const { useDefault } = this.state;

    const prefix = (useDefault ? defaultValue || '' : this.state.value) ? (
      <CloseOutlined
        onClick={this.clearInput}
        style={{
          cursor: 'pointer',
        }}
      />
    ) : (
      <SearchOutlined />
    );
    return (
      <Input
        {...inputProps}
        autoFocus={autoFocus}
        ref={(ref) => {
          this.inputRef = ref;
        }}
        placeholder={placeholder}
        value={useDefault ? defaultValue || '' : this.state.value}
        onChange={chaining(this.handleChange, onChange)}
        className={className}
        prefix={prefix}
        onPressEnter={chaining(this.handlePressEnter, onPressEnter)}
        style={style}
      />
    );
  }
}

export const story = (book) => {
  book.of.add(
    'default',
    book.withInfo()(() => (
      <SearchInput
        onSearch={book.action('search-change')}
        autoFocus={book.knobs.boolean('autoFocus', false)}
        placeholder={book.knobs.text('placeholder', 'Search here')}
      />
    ))
  );
};
