import React, { Component, ComponentType } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { observer } from 'mobx-react';
import getDisplayName from 'src/utils/get-display-name';

/**
 HOC that waits for promised props to resolve before rendering.

 `getPropsPromise(props)` returns a promise that resolves with an object
 that will be merged with `AsyncProps`'s props to obtain the props for
 the `WrappedComponent`.
 */
export default function asyncProps<
  Props,
  ResolvedProps extends Partial<Props>,
  LoadingProp extends keyof Props
>(
  getPropsPromise: (props: Props) => Promise<ResolvedProps>,
  loadingProp:
    | (Props[LoadingProp] extends boolean ? LoadingProp : never)
    | null = null
) {
  interface State {
    resolvedProps: ResolvedProps | undefined;
  }
  return (WrappedComponent: ComponentType<Props>) => {
    @observer
    class AsyncProps extends Component<Props, State> {
      state: Readonly<State> = {
        resolvedProps: undefined,
      };

      loadAsyncProps = async () => {
        const resolvedProps = await getPropsPromise(this.props);
        this.setState({
          resolvedProps,
        });
      };

      componentDidMount() {
        this.loadAsyncProps();
      }

      render() {
        if (!this.state.resolvedProps && loadingProp === null) {
          return null;
        }
        if (!this.state.resolvedProps) {
          if (React.isValidElement(loadingProp)) {
            return loadingProp;
          }
          return (
            <WrappedComponent
              {...this.props}
              {...(loadingProp && {
                [loadingProp]: true,
              })}
              reloadAsyncProps={this.loadAsyncProps}
            />
          );
        }
        return (
          <WrappedComponent
            {...this.state.resolvedProps}
            {...this.props}
            reloadAsyncProps={this.loadAsyncProps}
            {...(loadingProp
              ? {
                  [loadingProp]: false,
                }
              : {})}
          />
        );
      }
    }

    hoistNonReactStatics(AsyncProps, WrappedComponent);
    (
      AsyncProps as ComponentType<Props>
    ).displayName = `AsyncProps(${getDisplayName(WrappedComponent)})`;
    return AsyncProps as any;
  };
}
