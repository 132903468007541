import get from 'lodash/get';
import { computed, makeObservable } from 'mobx';
import ItemBase, { ItemJson } from 'src/models/aggregates/item';
import type TransactionStore from 'src/stores/transaction-store';
import type { ValidTransactionItemKind } from 'src/types/api';
import type { Item as TransactionItem } from 'src/types/proto/transactions';
import ItemNamespace from './item-namespace';

export type TransactionItemJson<K extends ValidTransactionItemKind> = ItemJson<
  TransactionItem,
  K
>;

export type ItemStore = TransactionStore;

export default class Item<
  TKind extends ValidTransactionItemKind
> extends ItemBase<TransactionItem, TKind> {
  itemNamespace: ItemNamespace;

  constructor(store: ItemStore, json: TransactionItemJson<TKind>) {
    super(store, json);
    makeObservable(this);
    this.itemNamespace = new ItemNamespace(this);
  }

  // Seems it's never used and no kindItem has `task` field
  @computed
  get taskMeta() {
    return this.kindItem.task;
  }

  @computed
  get transaction() {
    return this.aggregate;
  }

  @computed
  get thinTransaction() {
    return this.thinAggregate;
  }

  @computed
  get transactionTitle() {
    if (this.transaction) {
      return this.transaction.title;
    }
    if (this.thinTransaction) {
      return this.thinTransaction.title;
    }
    return undefined;
  }

  @computed
  get transactionParties() {
    if (this.transaction) {
      return this.transaction.parties;
    }
    if (this.thinTransaction) {
      return this.thinTransaction.parties;
    }
    return undefined;
  }

  @computed
  get transactionPermissions() {
    if (this.transaction) {
      return this.transaction.permissions;
    }
    if (this.thinTransaction) {
      return this.thinTransaction.permissions;
    }
    return [];
  }

  @computed
  get fieldValues() {
    return this.data.fieldValues || {};
  }

  getFieldValue(fieldId: string) {
    const fieldValues = get(this.data, 'fieldState.fieldValues', {});
    return get(fieldValues, [fieldId, 'value'], undefined);
  }

  itemsById = (ids: string[]) => {
    return ids.map((id) => this.store.itemsById.get(id)).filter(Boolean);
  };
}
