import {
  PROPERTY_NAMESPACE,
  TRANSACTION_NAMESPACE,
  TRANSACTION_PACKAGE_NAMESPACE,
} from 'src/stores/pdf-annotations-store';

export function getNamespaceScope({
  transactionPackageId,
  propertyInfoId,
} = {}) {
  if (transactionPackageId) {
    return {
      namespace: TRANSACTION_PACKAGE_NAMESPACE,
      itemId: transactionPackageId,
    };
  }

  if (propertyInfoId) {
    return {
      namespace: PROPERTY_NAMESPACE,
      itemId: propertyInfoId,
    };
  }

  return {
    namespace: TRANSACTION_NAMESPACE,
    itemId: null,
  };
}

export function getNamespaceScopeKey(...params) {
  const { namespace, itemId } = getNamespaceScope(...params);
  return [namespace, itemId].filter(Boolean).join(':');
}
