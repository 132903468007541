import camelCase from 'lodash/camelCase';
import { computed, makeObservable, override } from 'mobx';
import Model from 'src/models/base';
import type EnvelopeStore from 'src/stores/envelope-store';
import type TransactionStore from 'src/stores/transaction-store';
import {
  AggregateItem,
  AggregateItemByKind,
  AggregateItemDataByKind,
  ItemEdgeKind,
  ValidItemKind,
} from 'src/types/api';
import type { Item as EnvelopeItem } from 'src/types/proto/signing';
import type { Item as TransactionItem } from 'src/types/proto/transactions';
import type Transaction from '../transactions/transaction';

// TODO: simple patch remove after AggregateStore are converted to ts
export interface AggregateItemStore<T = unknown> {
  aggregatesById: Map<string, T>;
  thinAggregatesById: Map<string, T>;
}

export type ItemJson<
  T extends AggregateItem,
  K extends ValidItemKind<T>
> = AggregateItemByKind<T, K> & {
  fieldValues?: Record<string, unknown>;
};

export type AggregateStoreMap<T extends AggregateItem> =
  T extends TransactionItem
    ? TransactionStore
    : T extends EnvelopeItem
    ? EnvelopeStore
    : never;

export type AnyItemModel = Item<AggregateItem, ValidItemKind<AggregateItem>>;

export default class Item<
  TItem extends AggregateItem,
  TKind extends ValidItemKind<TItem>
> extends Model<ItemJson<TItem, TKind>> {
  store: AggregateStoreMap<TItem>;

  constructor(store: AggregateStoreMap<TItem>, json: ItemJson<TItem, TKind>) {
    super(json);
    makeObservable(this);
    this.store = store;
  }

  @override
  updateFromJson(json: ItemJson<TItem, TKind>) {
    super.updateFromJson(json);
    const camelCaseKind = camelCase(this.kind) as keyof ItemJson<TItem, TKind>;
    if (!this.data[camelCaseKind]) {
      this.data[camelCaseKind] = {} as any;
    }
  }

  @computed
  get id() {
    return (this.data as unknown as TItem).id;
  }

  @computed
  get clientId() {
    return (this.data as unknown as TItem).clientId;
  }

  @computed
  get idNumber() {
    return Number((this.data as unknown as TItem).id);
  }

  @computed
  get kind() {
    return (this.data as unknown as TItem).kind as TKind;
  }

  @computed
  get kindItem() {
    return this.data[
      camelCase(this.kind) as keyof ItemJson<TItem, TKind>
    ] as unknown as AggregateItemDataByKind<TItem, TKind>;
  }

  @computed
  get title() {
    return (this.data as unknown as TItem).title;
  }

  set title(value) {
    (this.data as unknown as TItem).title = value;
  }

  @computed
  get transId() {
    return (this.data as unknown as TItem).transId;
  }

  @computed
  get transactionId() {
    return (this.data as unknown as TItem).transId;
  }

  @computed
  get aggregateId() {
    return this.transactionId;
  }

  @computed
  get aggregate(): Transaction {
    return this.store.aggregatesById.get(this.aggregateId)!;
  }

  @computed
  get thinAggregate() {
    return this.store.thinAggregatesById.get(this.aggregateId);
  }

  @computed
  get outEdges(): TItem['outEdges'] {
    return (this.data as unknown as TItem).outEdges;
  }

  outEdgeIdsByKind = (kind: ItemEdgeKind<TItem>) => {
    return (this.outEdges as TransactionItem['outEdges'])
      .filter((e) => e.kind === kind)
      .map((e) => e.item2Id);
  };

  @computed
  get inEdges(): TItem['inEdges'] {
    return (this.data as unknown as TItem).inEdges;
  }

  inEdgeIdsByKind = (kind: ItemEdgeKind<TItem>) => {
    return (this.inEdges as TransactionItem['inEdges'])
      .filter((e) => e.kind === kind)
      .map((e) => e.item1Id);
  };

  @computed
  get createdAt() {
    return (this.data as unknown as TItem).createdAt;
  }

  @computed
  get deleted() {
    return (this.data as unknown as TItem).deleted;
  }

  @computed
  get createdAtNumber() {
    return Number(this.createdAt);
  }

  @computed
  get updatedAt() {
    return (this.data as unknown as TItem).updatedAt;
  }

  @computed
  get effectiveFrom() {
    return (this.data as unknown as TItem).effectiveFrom;
  }

  @computed
  get updatedAtNumber() {
    return Number(this.updatedAt);
  }

  @computed
  get effectiveFromNumber() {
    return Number(this.effectiveFrom);
  }

  // Data store version for this instance. Gets updated by the backend
  // on changes in data.
  // Can be `undefined` if this instance is not based on backend data.
  @computed
  get vers() {
    return (this.data as unknown as TItem).vers;
  }

  can(op: string) {
    return (this.data as unknown as TransactionItem).can[op];
  }
}
