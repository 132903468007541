

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import chaining from 'src/utils/chaining';
import classNames from 'classnames';
import { Select } from 'antd';
import AppIcon from 'src/components/common/app-icon';

const clsPrefix = 'app-single-select';

export default class SingleSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['default', 'large', 'small']),
    showSearch: PropTypes.bool,
    openOnFocus: PropTypes.bool,
    saveSelect: PropTypes.func,
    mode: PropTypes.oneOf(['default', 'combobox', 'tags', 'multiple']),
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    optionLabelProp: PropTypes.string,
  };

  static defaultProps = {
    openOnFocus: true,
    showSearch: false,
    filterOption: () => true,
  };

  static Option = Select.Option;
  static OptGroup = Select.OptGroup;

  setSelectRef = (selectRef) => {
    if (selectRef) {
      this.selectRef = selectRef;
    } else {
      this.selectRef = null;
    }
  };

  handleFocus = () => {
    if (this.props.openOnFocus && this.selectRef) {
      this.selectRef.focus();
    }
  };

  focus = () => {
    this.selectRef.focus();
  };

  blur = () => {
    this.selectRef.blur();
  };

  isOpen = () => {
    return !!(this.selectRef && this.selectRef.state.open);
  };

  getInputDomNode = (...args) => {
    this.selectRef.getInputDomNode(...args);
  };

  handleChange = () => {
    if (this.props.mode === 'multiple') {
      this.blur();
    }
  };

  render() {
    const {
      onFocus,
      onChange,
      className,
      optionLabelProp,
      filterOption,
      ...restProps
    } = this.props;

    return (
      <Select
        optionFilterProp="children"
        filterOption={filterOption}
        /* Attach the popup to this element instead of the body */
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        {...restProps}
        ref={chaining(this.setSelectRef, this.props.saveSelect)}
        className={classNames(clsPrefix, className, {
          [`${clsPrefix}--readonly`]: this.props.readOnly,
        })}
        onFocus={chaining(this.handleFocus, onFocus)}
        optionLabelProp={optionLabelProp || 'children'}
        onChange={chaining(this.handleChange, onChange)}
        showAction={['focus', 'click']}
        suffixIcon={
          <AppIcon
            className={`${clsPrefix}__select-arrow`}
            type="svg"
            size={16}
            importSvg={() =>
              import('!!svgJsx!src/images/icons/chevron-down3.svg')
            }
          />
        }
      />
    );
  }
}
