import fromPairs from 'lodash/fromPairs';
import { computed, makeObservable } from 'mobx';
import embeddedPlaceholderLg from 'src/images/properties/property-embedded-placeholder-lg.svg';
import embeddedPlaceholderMd from 'src/images/properties/property-embedded-placeholder-md.svg';
import embeddedPlaceholderSm from 'src/images/properties/property-embedded-placeholder-sm.svg';
import placeholderLg from 'src/images/properties/property-placeholder-lg.svg';
import placeholderMd from 'src/images/properties/property-placeholder-md.svg';
import placeholderSm from 'src/images/properties/property-placeholder-sm.svg';
import { PROPERTY_NAMESPACE } from 'src/stores/pdf-annotations-store';
import type RouterStore from 'src/stores/router-store';
import { ItemKind, ItemEdgeKind, ItemEdge } from 'src/types/proto/transactions';
import { BUYER_SIDE_ROLES, LISTING_SIDE_ROLES } from '../roles';
import Item, { ItemStore, TransactionItemJson } from './item';
import type Party from './party';

export const PROPERTY_TYPES = [
  ['SINGLE_FAMILY', 'Single Family'],
  ['MULTIFAMILY', 'Multifamily'],
  ['COMMERCIAL', 'Commercial'],
  ['LAND', 'Land'],
  ['FARM_AND_RANCH', 'Farm and Ranch'],
  ['MANUFACTURED_HOME', 'Manufactured Home'],
  ['CONDOMINIUM', 'Condominium'],
  ['OTHER', 'Other'],
];

const PLACEHOLDER_IMAGES = [placeholderSm, placeholderMd, placeholderLg];
export const EMBEDDED_PLACEHOLDER_IMAGES = [
  embeddedPlaceholderSm,
  embeddedPlaceholderMd,
  embeddedPlaceholderLg,
];

export const PROPERTY_TYPE_LABEL_BY_ENUM = fromPairs(PROPERTY_TYPES);

export type PropertyInfoJson = TransactionItemJson<'PROPERTY_INFO'>;

export default class PropertyInfo extends Item<'PROPERTY_INFO'> {
  constructor(store: ItemStore, json: PropertyInfoJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get namespace() {
    return PROPERTY_NAMESPACE;
  }

  getFieldIsUnlinked() {
    return false;
  }

  @computed
  get isPrimary() {
    return !this.isSecondary;
  }

  @computed
  get isSecondary() {
    return Boolean(this.kindItem.isSecondary);
  }

  @computed
  get address() {
    return this.kindItem.address;
  }

  @computed
  get propertyType() {
    return this.kindItem.propertyType;
  }

  @computed
  get propertyTypeLabel() {
    return PROPERTY_TYPE_LABEL_BY_ENUM[this.kindItem.propertyType];
  }

  @computed
  get yearBuilt() {
    return this.kindItem.yearBuilt;
  }

  @computed
  get lot() {
    return this.kindItem.lot;
  }

  @computed
  get images() {
    return this.kindItem.images || [];
  }

  @computed
  get coverPhoto() {
    const { isEmbedded } = this.store.parent.ui;
    const placeholderImages = isEmbedded
      ? EMBEDDED_PLACEHOLDER_IMAGES
      : PLACEHOLDER_IMAGES;
    return (
      this.kindItem.images?.[0] ||
      placeholderImages[
        Number(this.kindItem.addressId) % placeholderImages.length
      ]
    );
  }

  @computed
  get block() {
    return this.kindItem.block;
  }

  @computed
  get apn() {
    return this.kindItem.apn;
  }

  @computed
  get subdivision() {
    return this.kindItem.subdivision;
  }

  @computed
  get partyIds() {
    return this.outEdges
      .filter((e) => e.kind === ItemEdgeKind.PROPERTY_HAS_PARTY)
      .map((e) => e.item2Id);
  }

  getOrFetchParties = async () => {
    return this.store.getOrFetchItemMulti(
      this.transaction.id,
      'PARTY',
      this.partyIds
    );
  };

  @computed
  get parties() {
    return this.partyIds
      .map((partyId) => this.store.itemsById.get(partyId))
      .filter(Boolean) as Party[];
  }

  @computed
  get ownSideRoles() {
    return new Set(
      this.transaction.isSale ? LISTING_SIDE_ROLES : BUYER_SIDE_ROLES
    );
  }

  @computed
  get specificParties() {
    // Returns the parties that are specific to this propertyInfo (ie
    // that are not shared with the main transaction's parties)
    return this.parties.filter((p) =>
      p.roles.find((r) => !this.ownSideRoles.has(r))
    );
  }

  @computed
  get nonSpecificParties() {
    // Returns the parties that are not specific to this propertyInfo (ie
    // that are shared with the main transaction's parties)
    return this.parties.filter((p) =>
      p.roles.find((r) => this.ownSideRoles.has(r))
    );
  }

  @computed
  get offerPackageIds() {
    return this.outEdgeIdsByKind(ItemEdgeKind.PROPERTY_HAS_TRANSACTION_PACKAGE);
  }

  getOrFetchOfferPackages = async () => {
    return this.store.getOrFetchItemMulti(
      this.transaction.id,
      ItemKind.TRANSACTION_PACKAGE,
      this.offerPackageIds
    );
  };

  @computed
  get offerPackage() {
    return this.offerPackageIds
      .map((txnId) => this.store.itemsById.get(txnId))
      .filter(Boolean)[0];
  }

  @computed
  get hasAddress() {
    return Boolean(this.address);
  }

  get folderIds() {
    return this.outEdges
      .filter((edge) => edge.kind === ItemEdgeKind.PROPERTY_HAS_FOLDER)
      .map((edge) => edge.item2Id);
  }

  get defaultFolderId() {
    return this.outEdges?.find(
      (
        edge: {
          kind: string;
          item2Id: string;
        } & ItemEdge
      ) =>
        edge.kind === ItemEdgeKind.PROPERTY_HAS_FOLDER &&
        edge.propertyHasFolder!.isDefault
    )?.item2Id;
  }
}

export function navigateToProperty(
  transactionId: string,
  propertyId: string,
  router: RouterStore
) {
  router.navigate('transactions.transaction.properties.property', {
    transactionId,
    propertyId,
  });
}
