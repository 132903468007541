import React, { type FC } from 'react';



const clsPrefix = 'app-sharing-legal';

const SharingLegal: FC = () => {
  return (
    <p className={clsPrefix}>
      You are opting in to share these documents, including personally
      identifiable information (PII) and any information included in the data
      field of forms. By continuing, you are indicating that you have the right
      to share the information, including PII, of any other person(s) contained
      therein.
    </p>
  );
};

export default SharingLegal;
